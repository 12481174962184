const name = "utilsData";
//const namespace = "utils";

export const dataRegex = {
    regexEscapeRegex: /([.?*+\^$\[\]\\(){}|\-])/g,
    formatRegex: /(?:\{)(\d+)(?:\})/g,
    funcRegex: /^function(?:\s+([a-zA-Z$_][a-zA-Z0-9$_]*))?\s*\(((?:(?:\s*[a-zA-Z$_][a-zA-Z0-9$_]*)(?:\s*,\s*[a-zA-Z$_][a-zA-Z0-9$_]*)*)?)\s*\)\s*\{((?:.|\n|\r)*)\}$/,
    trimRegex: /^[\s\uFEFF]+|[\s\uFEFF]+$/g,
    urlifyRegex: /(\b(https?|ftp|file):\/\/[\-A-Z0-9+&@#\/%?=~_|!:,.;]*[\-A-Z0-9+&@#\/%=~_|])/gi,
    numberFormatRegex: /^([^\d,. ']*)(?:[\d]+|\d{1,3}(?:(?:(')\d{3})+|(?:(,)\d{3})+|(?:( )\d{3})+))(?:(\.)\d+)?([^\d,. ']*)$/,
    dateFormatRegex: /(d{1,4}(?!d)|M{1,4}(?!M)|y{4}(?!y)|y{2}(?!y)|H{1,2}(?!H)|m{1,2}(?!m)|s{1,2}(?!s)|S)/g,
    stripStylesRegex: /(?:<[^\s>]+[^>]*)(\s+style\s*=\s*"[^"]*")(?:[^>]*\>)/gi,
    formatRegexes: {}, /* used to cache regexes created in order to unformat strings */
    dateFormatTokenParsers: {
        yyyy: { regex: "(\\d{4})", parser: function (v) { this.setUTCFullYear(v); } },
        MMMM: { regex: "([a-zA-Z]{3,})", parser: function (v) { this.setUTCMonth(getMonthByName(v) || 0); } },
        dddd: { regex: "([a-zA-Z]{3,})", parser: null },
        MMM: { regex: "([a-zA-Z]{3})", parser: function (v) { this.setUTCMonth(getMonthByShortName(v) || 0); } },
        ddd: { regex: "([a-zA-Z]{3})", parser: null },
        yy: { regex: "(\\d{2})", parser: function (v) { this.setUTCFullYear(this.getUTCFullYear().toString().slice(0, 2) + v); } },
        MM: { regex: "(\\d{2})", parser: function (v) { this.setUTCMonth(+v - 1); } },
        dd: { regex: "(\\d{2})", parser: function (v) { this.setUTCDate(v); } },
        HH: { regex: "(\\d{2})", parser: function (v) { this.setUTCHours(v); } },
        mm: { regex: "(\\d{2})", parser: function (v) { this.setUTCMinutes(v); } },
        ss: { regex: "(\\d{2})", parser: function (v) { this.setUTCSeconds(v); } },
        M: { regex: "(\\d{1,2})", parser: function (v) { this.setUTCMonth(+v - 1); } },
        d: { regex: "(\\d{1,2})", parser: function (v) { this.setUTCDate(v); } },
        H: { regex: "(\\d{1,2})", parser: function (v) { this.setUTCHours(v); } },
        m: { regex: "(\\d{1,2})", parser: function (v) { this.setUTCMinutes(v); } },
        s: { regex: "(\\d{1,2})", parser: function (v) { this.setUTCSeconds(v); } },
        S: { regex: "(st|nd|rd|th)", parser: null }
    },
    isodate: /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,7}))?Z?$/,
    instanceTypes: {
        "arraybuffer": window.ArrayBuffer,
        "bytearray": window.Uint8Array,
        "blob": window.Blob,
        "array": Array,
        "date": Date,
        "xdate": window.XDate ? window.XDate : Date,
        "number": Number,
        "string": String,
        "object": Object
    },
};

const cloneObject = obj =>{
    /*let vObject = {};
    Object.keys(obj).forEach(key=>{
        vObject[key] = obj[key];
    });

    return vObject;*/

    var copy;

    if (null == obj || "object" != typeof obj) return obj;

    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = cloneObject(obj[i]);
        }
        return copy;
    }

    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = cloneObject(obj[attr]);
        }
        return copy;
    }
}

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
//export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}




function getKey(row,keys){
    let vKey = {};
    keys.forEach(key=>{
        vKey[key] = row[key];
        
    });
    return vKey;
}

function updateAggregate(row,aggregates){


}

function getGroupBy(list,keys){
    let vLength = list.length;
    let vKeys = keys.filter(x=>x.groupByOrder).map(x=>x.name);
    let vAggregates = keys.filter(x=>x.groupByAggregate);
    let vGroups = {};
    for(let i =0; i< vLength;i++){
        let vRow = list[i];
        let vKey = getKey(vRow,vKeys);
        if(vGroups[vKey]){

        }
    }
}

function sortByKey(key,order,type) {
    if(!type) type === "string";
    return function(a, b) {
        var a = a[key];
        var b = b[key];
        if(type === "string"){
            if(a && b){
                if(a !== null) a = a.toString().toUpperCase();
                if(b !== null) b = b.toString().toUpperCase();
            }
        }else if(['datetime','date'].indexOf(type) > -1){
            a = new Date(a);
            b = new Date(b);
        }
        switch(type) {
            case "number":
                return order === "asc" ? (a-b) : (b-a);
                break;
            case "string" :
            case "date":
            case "datetime":
            case "uniqueidentifier":
                return order === "asc"?((a < b) ? -1 : (b < a) ? 1: 0) : ((a > b)? -1 : (b > a)? 1 : 0);
                break;
            default:
                return 0;
        }
    };
}

function tryParseJson(pJson){
    try{
        return JSON.parse(pJson);
    }catch(ex){
        return null;
    }
}
/**
 * {data,id,other options}
 */

/**
 * @description
 * Takes an array of field names and an array of data rows, where each row is an array of values, and 
 * maps them to an array of key-value objects.
 * 
 * @param pFields array Array of field names
 * @param pData array Array of data rows
 *
 * @returns array Array of key-value objects.
 */
function mapDataArraysToObjects(pFields, pData) {
    return pData.map(x => mapDataArrayToObject(pFields, x));
}

/**
 * @description
 * Takes an array of field names and an array of of values and 
 * maps them to a key-value object.
 * 
 * @param pFields array Array of field names
 * @param pData array Array of data rows
 *
 * @returns object Key-value object.
 */
function mapDataArrayToObject(pFields, pData) {
    let obj = {};
    pFields.forEach((y, i) => obj[y] = pData[i]);
    return obj;
}

function isJsonString(str) {
    if (str === null || str === "") {
        return false;
    }
    try {

        JSON.parse(str);
    } catch (e) {
        return false;
    }
    if (str.length === 0) {
        return false;
    } else {
        return true;
    }
}


export default {dataRegex, cloneObject, groupBy,name, sortByKey, tryParseJson, mapDataArrayToObject, mapDataArraysToObjects, isJsonString} 
